<i18n>
{
  "en": {
    "pr_header": "PR"
  },
  "ja": {
    "pr_header": "PR"
  }
}
</i18n>

<template>
<div class="container">
  <div class="section">
    <h2 class="is-primary">{{ $t('pr_header') }}</h2>
    <div class="grid" v-for="(banners, groupName) in badminBannerGroups" :key="groupName">
      <div class="grid-item" v-for="banner in banners" :key="banner.display_order" @click="clickTrack('Top Page', 'PR', banner.google_analytics_label);">
        <!-- link to external site -->
        <span v-if="!banner.pathname || banner.url_target === true">
          <a
            :href="banner.url"
            :data-site="banner.google_analytics_label"
            class="nav__banner"
            target="_blank"
            rel="noopener">
            <img class="media-image" :src="`/dyn/dla/images/${banner.img_path}`">
          </a>
        </span>
        <!-- link to same site -->
        <span v-if="banner.pathname">
          <router-link
            :to="banner.pathname"
            :data-site="banner.google_analytics_label">
            <img class="media-image" :src="`/dyn/dla/images/${banner.img_path}`">
          </router-link>
        </span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */
/* eslint no-unneeded-ternary: 0 */

import Badmin from '@/assets/js/services/Badmin';

export default {
  data() {
    return {
      badminBannerGroups: {},
    };
  },
  beforeCreate() {
    const badminSvc = new Badmin(this.$i18n.locale, this.$store.getters['user/typeID']);
    badminSvc.getBanners('pr').then((banners) => {
      this.badminBannerGroups = banners;

      // hide pr header in the home page if there are no series set
      this.$emit('prEval', (Object.keys(banners).length === 0) ? false : true);
    });
  },
  methods: {
    clickTrack(category, action, label = null, value = null) {
      this.$analytics.trackEvent(category, action, label, value);
    },
  },
  computed: {
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  watch: {
    userTypeID(newVal, oldVal) {
      if (newVal !== oldVal) {
        const badminSvc = new Badmin(this.$i18n.locale, this.$store.getters['user/typeID']);
        badminSvc.getBanners('pr').then((banners) => {
          this.badminBannerGroups = banners;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
